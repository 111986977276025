'use client';

import React from 'react';
import { appMeta, Routes } from '@explorer/src';
import { useRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';
import { Button, Grid, Image, Typography } from '@explorer/core';
import { ShadowPaper } from '@explorer/core/presets';
import { MainLayout } from '@explorer/components/shared';
import {
  PageAuthenticationWrapper,
  PermissionWrapper,
} from '@explorer/containers';
import { useUser } from '@explorer/stores';
import { useFeatureFlag } from '@explorer/hooks';
import { homeModules } from '@explorer/components/home/homeModules';

/**
 * Page Route
 */
const route: Routes = '/';

/**
 * Page Meta
 */
const pageMeta = appMeta[route];

/**
 * Page Component (use an UniqueName to improve Hot Reload performance)
 */
const PageHome = () => {
  const { push } = useRouter();

  const { userHasPermissions } = useUser();

  const isSuperUser = userHasPermissions(['explorer:superuser']);
  const isStaff = userHasPermissions(['explorer:staff']);

  const { value: enableManageRequestFeature, isLoading: featureFlagsLoading } =
    useFeatureFlag<boolean>('show-msp-access-request-feature');

  const { value: enableZeroDayAlert } = useFeatureFlag<boolean>(
    'explorer.zero-day-alert',
  );

  return (
    <PageAuthenticationWrapper pageMeta={pageMeta}>
      <MainLayout title="Coalition Explorer">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={4}
        >
          {homeModules(
            isSuperUser,
            isStaff,
            enableManageRequestFeature,
            featureFlagsLoading,
            enableZeroDayAlert,
          ).map(
            ({
              title,
              description,
              permissions,
              row,
              links,
              hasAtLeastOnePermission,
            }) => (
              <PermissionWrapper
                key={uuidv4()}
                has={permissions}
                hasAtLeastOnePermission={hasAtLeastOnePermission}
              >
                <Grid item xs={row ? 12 : 6}>
                  <ShadowPaper px={4} py={4}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item mr={2}>
                        <Image
                          src="/meta/coalition-48x48.png"
                          title="Coalition Inc."
                          alt="Coalition Inc logo"
                          noUserSelect
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="h5"
                          component="h2"
                          color="textPrimary"
                          label={title}
                        />
                        <Typography
                          variant="bodyS"
                          gutterBottom
                          color="textSecondary"
                          label={description}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      mt={3}
                    >
                      {links.map(({ label, url }) => (
                        <Grid item pr={1} key={uuidv4()}>
                          <Button
                            variant="text"
                            color="primary"
                            label={label}
                            onClick={() => push({ pathname: url })}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </ShadowPaper>
                </Grid>
              </PermissionWrapper>
            ),
          )}
        </Grid>
      </MainLayout>
    </PageAuthenticationWrapper>
  );
};

export default PageHome;
